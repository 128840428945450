@import 'fonts.css';

:root {
  --body-background: #201726;
  --body-color: #eee;
  --heading-color: white;
  --action-color: #FFE672;
  --action-color-secondary: #FFE67233;
  --action-color-secondary-50: #FFE67211;
  --accent-color: #1B4669;
  --accent-color-secondary: #7fb6e3;
  --consideration-color: #ff749b;
  --consideration-color-secondary: #9d5757;
}

body {
  background: var(--body-background) url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' opacity='25%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  background-size: 30%;
  color: var(--body-color);
  font-family: 'JetBrains Mono', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-style: italic;
  font-weight: 300;
  padding: 6rem 1rem 4rem;
  margin: 0;
  font-size: 100%;
  line-height: 1.5;
  position: relative;
  
  @media (max-width:500px) {
    font-size: 75%;
  }
  
  @media (max-width:1024px) {
    padding-top: 3rem;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0.4;
    min-height: 50rem;
    background-image: linear-gradient(165deg, #009CF9 1%, #000000aa 50%, rgba(0,0,0,0.00) 77%);
  }
}

a {
  color: var(--consideration-color);
  text-decoration: underline;
  text-decoration-color: var(--consideration-color-secondary);
  text-shadow: 0 0 0.5rem var(--consideration-color-secondary);
  transition: text-decoration-color 0.5s ease, text-shadow 0.5s ease;
  
  &:hover, &:focus {
    text-shadow: 0 0 1rem var(--consideration-color);
    text-decoration-color: var(--consideration-color);
  }
}

a.primary-action {
  color: var(--action-color);
  text-decoration-color: var(--action-color-secondary);
  text-shadow: 0 0 0.5rem var(--action-color);
  
  &:hover, &:focus {
    text-shadow: 0 0 1rem var(--action-color);
    text-decoration-color: var(--action-color);
  }
}

h1 {
  margin: 1rem 0 3rem;
  font-weight: 300;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
  
  @media (max-width:500px) {
    font-size: 1.5rem;
  }
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 0 auto 4rem;
  
  @media (min-width: 500px) and (max-width:1024px) {
    margin-left: 1rem;
  }
  
  max-width: 50rem;
  min-height: calc(100vh - 200px);
  padding: 2rem 4rem 3rem 3rem;
  position: relative;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
  
  perspective: 1000px;
  perspective-origin: 50% 50%;
  
  @media (min-width: 500px) {
  
    &:before, &:after {
      content: '';
      position: absolute;
      border-radius: 10px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -10;
      pointer-events: none;
      transition: transform 1s ease 0.7s, opacity 1s ease-in-out 0.7s;
    }
    
    &:before {
      border: 1px rgba(255,255,255,0.5) solid;
      opacity: 0.5;
      transform: skewY(1deg) translateX(1rem) translateY(1.2rem);
    }
    
    &:after {
      transition: transform 1.1s ease 0.6s, opacity 1.1s ease-in-out 0.6s;
      border: 2px rgba(255,255,255,0.7) solid;
      opacity: 0.5;
      transform: skewY(-0.5deg) translateX(-0.5rem) translateY(-0.3rem);
    }
    
    &.shown {
      &::before {
        border: 1px rgba(255,255,255,0.5) solid;
        opacity: 1;
        transform: skewY(3deg);
      }
      
      &::after {
        border: 2px rgba(255,255,255,0.7) solid;
        opacity: 1;
        transform: skewY(-1deg) translateX(-1rem) translateY(-1rem);
      }
    }
  }
}

li {
  margin-bottom: 0.7rem;
}

footer {
  text-align: right;
  font-size: 0.6em;
  color: white;
  p {
    margin-bottom: 0;
  }
}

hr {
  border: none;
  border-top: 0.05rem solid white;
  margin: 3rem 0;
  max-width: 10rem;
}

h2 {
  color: var(--heading-color);
  font-size: 1.4em;
  margin: 3.5em 0 2em;
  font-style: italic;
  font-weight: 200;
  
  @media (max-width:500px) {
    font-size: 1.2rem;
  }
}

blockquote.bubble {
  margin-left: 0;
}

.bubble {
  background: #00000099;
  padding: 0.8rem 1rem 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 2px solid white;
  font-size: 1.2em;
  font-style: normal;
  font-family: "Workbench", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-variation-settings: "BLED" 0, "SCAN" 0;
  letter-spacing: .05em;
  animation: screen-glow 3000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: relative;
  z-index: 1;
  hanging-punctuation: first last;
  
  strong {
    background: none;
    color: var(--accent-color-secondary);
  }
  
  p {
    margin: 0;
  }
  
  &.offbeat-1 {
    animation-delay: 200ms;
  }
  
  &.size-1 {
    transform: scale(1.08) translateX(-0.15rem);
  }
  
  &.size-2 {
    width: 60%;
    transform: translateX(-0.2rem);
  }
  
  &.size-3 {
    font-size: 1.4em;
    width: 80%;
    transform: translateX(0.5rem);
  }
  
  &.size-4 {
    font-size: 1.6em;
  }
  
  text-shadow: 0.5rem 0.5rem 0.3rem #ffffff22;
  
  &:before {
    position: absolute;
    content: '';
    border-radius: 0.5rem;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px solid #ffffff33;
    z-index: 0;
    transform: translateX(0.5rem) translateY(0.5rem);
  }
}

strong {
  background: #000000aa;
}

@media (min-width: 500px) {
  .w-60 {
    max-width: 75%;
  }
  
  .w-75 {
    max-width: 85%;
  }
  
  .w-50 {
    max-width: 60%;
  }
}

@media (min-width: 1024px) {
  .w-60 {
    max-width: 60%;
  }
  
  .w-75 {
    max-width: 75%;
  }
  
  .w-50 {
    max-width: 50%;
  }
}

code {
  background: var(--accent-color);
  padding: 0.2em 0.4em;
  border-radius: 0.8em 0.3em;
}

@keyframes screen-glow {
  0% {
    font-variation-settings: "BLED" 0, "SCAN" 0;
    opacity: 1;
  }
  85% {
    font-variation-settings: "BLED" 0, "SCAN" 0;
    opacity: 1;
  }
  86% {
    font-variation-settings: "BLED" 0, "SCAN" -800;
    opacity: 0.8;
  }
  87% {
    font-variation-settings: "BLED" 0, "SCAN" -400;
    opacity: 1;
  }
  92% {
    font-variation-settings: "BLED" 0, "SCAN" -200;
    opacity: 1;
  }
  93% {
    font-variation-settings: "BLED" 0, "SCAN" -800;
    opacity: 0.8;
  }
  94% {
    font-variation-settings: "BLED" 0, "SCAN" 0;
    opacity: 1;
  }
  100% {
    font-variation-settings: "BLED" 0, "SCAN" 0;
    opacity: 1;
  }
}

.the-fix-wrapper {
  @media (max-width: 500px) {
    margin: 0 -2rem;
    padding: 0 2rem;
    overflow: hidden;
  }
}

.the-fix {
  margin: 4rem -5rem;
  padding: 3rem 5rem;
  position: relative;
  
  h2 {
    margin-top: 0;
    font-weight: 300;
  }
  
  h3 {
    font-size: 1rem;
    text-transform: uppercase;
    strong {
      background: none;
      color: black;
      display: inline-block;
      position: relative;
      
      &:before {
        content: '';
        position: absolute;
        top: -0.1rem; bottom: -0.1rem;
        left: -1rem; right: -2rem;
        z-index: -1;
        background: var(--action-color);
        transform: skewX(-5deg);
      }
    }
  }
  
  &:before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    transform: skewY(1deg);
    z-index: -1;
    background: #00000066;
    border: 4px solid var(--action-color);
    border-radius: 0.5rem;
    box-shadow: inset 0 0 2rem var(--action-color);
  }
}

ul.⚡️ {
  list-style: none;
  li {
    position: relative;
    margin-bottom: 2em;
    font-size: 1.4em;
    
    &:before {
      position: absolute;
      display: block;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='27' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 .097.032 17.951h14.537l-.687 5.116 12.728-7.116-10.707-8.096-.56 4.671H5z' fill='%23FFE672' fill-rule='evenodd'/%3E%3C/svg%3E");
      width: 2.0rem;
      height: 1.7rem;
      left: -2.5rem;
      top: -0.2rem;
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}

.arrow {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='27' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 .097.032 17.951h14.537l-.687 5.116 12.728-7.116-10.707-8.096-.56 4.671H5z' fill='%23FFE672' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.zipmessage-container {
  margin: 1rem -2rem;
  padding: 1rem;
  background: #ffffff33;
  border-radius: 0.6rem;
  transform: skewY(1deg);
  iframe {
    border: 0;
    border-radius: 0.3rem;
    margin: 0 auto;
    display: block;
    box-shadow: 0 0 1rem black;
  }
  
  @media (min-width: 500px) {
    margin: 1rem -1rem;
  }
}

.zipmessage-explainer {
  position: relative;
  padding: 1rem;
  
  p {
    margin: 0;
  }
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: #000000cc;
    border: 4px solid var(--action-color);
    border-radius: 0.2rem;
    transform: skewX(-2deg) translateX(0.1rem) translateY(0.1rem);
    z-index: -10;
    box-shadow: 0 0 1rem black;
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 3rem;
    left: calc(100% + 0.3rem);
    transform: translateX(-0.2rem);
    height: 3rem;
    bottom: 0.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='45' height='42' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.656.081 25 4.65l-4.968 19.046H14l2 18.008 23.5-18.008-9.342-.088L44.964.081z' fill='%23FFE672' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
  }
  
  @media (max-width: 500px) {
    width: calc(100% - 3rem);
    
    &:after {
      left: 100%;
      width: 3rem;
      bottom: -0.7rem;
    }
  }
  
  transform: translateX(-1rem);
  
  @media (min-width: 500px) {
    margin-bottom: -2.5rem;
    z-index: 20;
  }
}

ul.experience {
  li {
    margin-bottom: 1.5rem;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  li.highlight {
    transform: scale(1.05) translateX(-0.5rem) translateY(-0.5rem);
    position: relative;
    
    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: -0.5rem; bottom: -0.5rem;
      left: -2rem; right: -0.5rem;
      background: #00000099;
      border-radius: 0.5rem;
      border: 2px solid white;
    }
  }
  
  li.diminish {
    opacity: 0.5;
  }
}

.text-filter-wrapper {
  position: relative;
  
  &.text-filter-enabled {
    .text-filter-search {
      display: block;
    }
  }
}

.text-filter-search {
  display: none;
  width: 16rem;
  height: 0;
  margin: 0 -2em 0 auto;
  position: sticky;
  border-radius: 0.4rem;
  text-align: right;
  top: 0.5rem;
  
  input[type="search"] {
    display: inline-block;
    width: 100%;
    background: var(--body-background);
    border: 0.2rem solid #ffffff55;
    padding: 0.3rem 0.3rem 0.2rem;
    color: var(--action-color);
    border-radius: 0.4rem;
    
    box-shadow: 0.2rem 0.2rem 0 black;
    
    &, &::placeholder {
      
      font-size: 16px;
      font-family: 'JetBrains Mono', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        "Helvetica", "Arial", sans-serif;
      font-style: italic;
      font-weight: 100;
    }
    
    &:focus {
      border-color: var(--action-color);
    }
  }
}