@font-face {
  font-family: 'JetBrains Mono';
  font-style: italic;
  font-weight: 300;
  src: local('Jetbrains Mono Light Italic'), url('/fonts/JetBrainsMono-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: italic;
  font-weight: 200;
  src: local('Jetbrains Mono ExtraLight Italic'), url('/fonts/JetBrainsMono-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Workbench';
  font-style: normal;
  font-weight: 600;
  src: local('Workbench[BLED,SCAN]'), url('/fonts/Workbench[BLED,SCAN].woff2') format('woff2');
}